body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bodyText {
  color: black;
}

.Card {
  float: center
}

.bodyText {
  margin-top: 5%;
}

/*
.vl {
  border-left: 1px solid gray;
  height: 400px;
  
}
*/
hr {
  border-top: 1px solid gray !important;
}

#gerson {
  padding-right: 1rem;
}

#me {
  padding-right: 1rem;
}

.jumbotron {
  
  padding: 0% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
  margin-bottom: 0% !important;
  
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  background-repeat: no-repeat;
  /*
  background-size: cover;
  */
}

.btn-dark {
  background-color:black !important;
}


.succege-msg {

color: green !important;
}


.err-msg {
  color: red !important;
}

.jumbotron {
  
  background-position: center 10%;
  background-size: cover;
}

@media (max-width: 979px) {
  .jumbotron { 
    display: none;
  }
}