

.socialMediaIcons {
    font-size: 1.0rem;
}

#kT {
    font-size: 1.0rem;
}


a{
    color: black;
}

a:hover{
    color: rgb(120, 206, 112) !important;
}